/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import PackageDetail from '@paquery-team/lib-package-detail';
import { notification } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import useMarketplaces from 'redux/marketplaces';
import { marketplacesItemsSelector } from 'redux/marketplaces/selectors';
import useStore from 'redux/store';
import { actions as storeActions } from 'redux/store/slice';
import { storeItemsSelector } from 'redux/store/selectors';
import { zoneItemsSelector } from 'redux/zones/selectors';
import { authProfile } from 'redux/auth/selectors';
import {
  countriesGlobalsSelector,
  selectGlobals,
} from 'redux/globals/selectors';
import API from 'constants/api';
import { getZoneFromAddress } from 'constants/operations';
import PackageService from 'services/PackageService';
import rest, { getData } from 'util/Api';
import { PACKAGE } from 'constants/packages';
import { MARKETPLACE_OWNERTYPE } from 'constants/defaultValues';

const validationSchema = Yup.object().shape({
  externalCode: Yup.string()
    .matches(/^\S+$/, 'El código de seguimiento no debe contener espacios.')
    .nullable(),
  caption: Yup.string().required('Especifique el contenido del paquete.'),
  estimatedCost: Yup.number().nullable(),
  detail: Yup.string().nullable(),
  packageSize: Yup.number().typeError(
    'Es necesario indicar el tamaño del paquete.',
  ),
  packageType: Yup.number().typeError('Es necesario elegir un tipo de envío'),
  deliveryTerm: Yup.number().when('packageType', {
    // when packageType is send (2) or store withdrawal (4) deliveryTerm is required
    is: (packageType) => packageType === 2 || packageType === 4,
    then: Yup.number().typeError('Es necesario elegir un plazo de entrega'),
    otherwise: Yup.number().nullable(),
  }),
  ownerID: Yup.number().typeError('Es necesario elegir un marketplace'),
  storeId: Yup.number().typeError('Es necesario elegir una tienda'),
  shippingScheduleOrigin: Yup.object().when('packageType', {
    // when package is type pickUp, name and address are not required
    is: 3,
    then: Yup.object().shape({
      name: Yup.string().nullable(),
      phone: Yup.number().typeError('Teléfono invalido').nullable(),
      shippingAddress: Yup.object().shape({
        addressDetail: Yup.string().nullable(),
      }),
    }),
    otherwise: Yup.object().shape({
      name: Yup.string().required('Especifique el nombre del punto de retiro.'),
      phone: Yup.number().typeError('Teléfono invalido').nullable(),
      shippingAddress: Yup.object().shape({
        addressDetail: Yup.string().required('Indique la dirección de retiro.'),
      }),
    }),
  }),
  shippingScheduleDestination: Yup.object().shape({
    name: Yup.string().required('Indique el nombre del destinatario.'),
    phone: Yup.number().typeError('Teléfono invalido').nullable(),
    destinationEmail: Yup.string()
      .email('Ingrese un formato de correo válido.')
      .required('Ingrese una dirección de mail'),
    shippingAddress: Yup.object().shape({
      addressDetail: Yup.string().required('Indique la dirección de entrega'),
    }),
    deliveryNote: Yup.boolean().nullable(),
    paymentOnDelivery: Yup.boolean().nullable(),
    paymentAmount: Yup.number().when('paymentOnDelivery', {
      is: true,
      then: Yup.number()
        .required('Es necesario especificar un monto')
        .typeError('El monto debe ser un numero valido'),
      otherwise: Yup.number().nullable(),
    }),
  }),
  additionalCode: Yup.string().when('packageType', {
    // when package is type flex, additionalCode is required
    is: 5,
    then: Yup.string().required('Es necesario especificar el codigo de venta'),
    otherwise: Yup.string().nullable(),
  }),
});

const disabledInputs = {
  status: true,
  reason: true,
  shippingScheduleOrigin: {
    shippingAddress: {
      geoKey: true,
    },
  },
  shippingScheduleDestination: {
    scheduledDate: true,
    shippingAddress: {
      geoKey: true,
    },
  },
};

const initialValues = {
  externalCode: '',
  status: null,
  estimatedCost: '0',
  caption: '',
  reason: '',
  detail: '',
  ownerID: null,
  storeId: null,
  packageSize: null,
  packageType: null,
  deliveryTerm: null,
  additionalCode: '',
  shippingScheduleOrigin: {
    name: '',
    comment: '',
    phone: '',
    shippingAddress: {
      addressDetail: '',
      geoKey: '',
      comment: '',
    },
  },
  shippingScheduleDestination: {
    name: '',
    phone: '',
    destinationEmail: '',
    comment: '',
    zone: null,
    shippingAddress: {
      addressDetail: '',
      geoKey: '',
      comment: '',
    },
  },
};

const getPersonName = (fullName) => fullName.split(' ')[0];

const getPersonLastName = (fullName) => fullName.split(' ').slice(-1)[0];

const PackageSend = () => {
  useMarketplaces({ initialize: true });
  useStore();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const globals = useSelector(selectGlobals);
  const stores = useSelector(storeItemsSelector);
  const marketplaces = useSelector(marketplacesItemsSelector);
  const countries = useSelector(countriesGlobalsSelector);
  const zones = useSelector(zoneItemsSelector);
  const profileLogged = useSelector(authProfile);

  const [saving, setSaving] = useState(false);
  const [initialValuesDuplicate, setInitialValuesDuplicate] =
    useState(initialValues);
  const modifiedStores = useRef(null);

  useEffect(() => {
    const getPackage = async (id) => {
      try {
        const response = await PackageService.getPackage(id);
        if (response.storeId) {
          const store = await getData(API.stores.getById(response.storeId));
          modifiedStores.current = [...stores, store];
        }
        setInitialValuesDuplicate({
          ...response,
          shippingScheduleDestination: {
            ...response.shippingScheduleDestination,
            zone: response.shippingScheduleDestination.zone?.id,
          },
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('error', err);
      }
    };
    if (location.state?.id) {
      getPackage(location.state.id);
    }
  }, [location]);

  const getPayload = async (values) => {
    const resolvedZone = await getZoneFromAddress(
      values.shippingScheduleDestination.shippingAddress.addressDetail,
      profileLogged.logisticOperatorID,
    );
    return {
      ...values,
      ownerType: MARKETPLACE_OWNERTYPE,
      shippingScheduleDestination: {
        ...values.shippingScheduleDestination,
        zone: {
          id: values.shippingScheduleDestination.zone,
        },
        shippingAddress: {
          ...values.shippingScheduleDestination.shippingAddress,
          lat: resolvedZone.lat,
          lng: resolvedZone.lng,
          postalCode: resolvedZone.postalCode,
          resolutedAddress: resolvedZone.resolutedAddress,
          resolutedStreet: resolvedZone.resolutedStreet,
          resolutedStreetNumber: resolvedZone.resolutedStreetNumber,
          resolutedLocality: resolvedZone.resolutedLocality,
        },
      },
      user: {
        email: values.shippingScheduleDestination.destinationEmail,
        lastName: getPersonLastName(values.shippingScheduleDestination.name),
        name: getPersonName(values.shippingScheduleDestination.name),
        phone: values.shippingScheduleDestination.phone,
      },
    };
  };

  const submitEdition = async (values) => {
    setSaving(true);
    const payload =
      values.packageType === PACKAGE.TYPES.PICKUP
        ? await getPayload({
            ...values,
            shippingScheduleOrigin: values.shippingScheduleDestination,
          })
        : await getPayload(values);
    try {
      const response = await rest.post(API.packages.send, payload);
      if (rest.isSuccessResponse(response)) {
        const { externalCode } = response.data.data;
        notification.success({
          message: 'Envío de paquete',
          description: `El paquete ${externalCode} ha sido creado correctamente.`,
        });
        setTimeout(() => {
          history.push('/packages');
        }, 1000);
      }
    } catch (error) {
      notification.error({
        message: 'Ocurrio un problema',
        description: `Ha ocurrido un error al enviar el paquete${
          error.message ? `: ${error.message}` : '.'
        }`,
      });
      setSaving(false);
    }
  };

  const selectMarketplace = (marketplaceId) => {
    dispatch(storeActions.all(marketplaceId));
  };

  const handleBlur = async (destinationAddressValue, setFieldValue) => {
    if (!destinationAddressValue) return;
    try {
      const zone = await getZoneFromAddress(
        destinationAddressValue,
        profileLogged.logisticOperatorID,
      );
      if (zone.id)
        setFieldValue(
          'shippingScheduleDestination.zone',
          parseInt(zone.id, 10),
        );
      else
        notification.error({
          message: 'Oops!',
          description: 'No se ha encontrado una zona para esa dirección.',
        });
    } catch (error) {
      notification.error({
        message: 'Oops!',
        description: 'Ha ocurrido un error. Intente de nuevo más tarde.',
      });
    }
  };

  return (
    <PackageDetail
      packageTypes={globals.packages.type}
      marketplaces={marketplaces}
      stores={modifiedStores.current || stores}
      zones={zones}
      saving={saving}
      countries={countries}
      loaded={!!marketplaces}
      disabledInputs={disabledInputs}
      initialValues={initialValuesDuplicate}
      validationSchema={validationSchema}
      onSubmit={submitEdition}
      onChangeMarketplace={selectMarketplace}
      onBlurDeliveryAddress={handleBlur}
    />
  );
};

export default PackageSend;
