import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { DEFAULT_PAGINATE } from 'constants/defaultValues';
import SITELINKS from 'constants/sitelinks';
import DeleteModal from 'components/deleteModal';
import PaqueryTable, { TableHeader } from '@paquery-team/lib-table';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'redux/zones/slice';
import {
  zoneWithGeoJsonFilteredItemsSelector,
  zoneWithGeoJsonLoadedSelector,
} from 'redux/zones/selectors';
import ZoneService from 'services/ZoneService';
import DeleteRecordButton from 'components/deleteRecordButton';
import EditRecordButton from 'components/editRecordButton';

const codeColumn = {
  title: 'Código',
  dataIndex: 'code',
};
const nameColumn = {
  title: 'Nombre',
  dataIndex: 'name',
};
const detailColumn = {
  title: 'Detalle',
  dataIndex: 'detail',
};

const editZoneColumn = {
  align: 'center',
  render: (_, record) => (
    <EditRecordButton link={SITELINKS.zones.edit.forId} record={record} />
  ),
};
const dataColumns = [nameColumn, detailColumn, codeColumn];

const ZoneList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loaded = useSelector(zoneWithGeoJsonLoadedSelector);
  const { items, pageable } = useSelector(zoneWithGeoJsonFilteredItemsSelector);

  const deleteAction = (id, name) => {
    const deleteRequest = async () => {
      let response;
      try {
        response = await ZoneService.deleteById(id);
      } catch (error) {
        response.data.message = error.message;
      } finally {
        dispatch(actions.refreshPage());
      }
      return response;
    };
    DeleteModal({
      title: `¿Estas seguro de que deseas eliminar la zona ${name}?`,
      onConfirmation: deleteRequest,
    });
  };

  const deleteZoneColumn = {
    align: 'center',
    render: (_, record) => (
      <DeleteRecordButton
        onClick={() => deleteAction(record.key, `${record.name}`)}
      />
    ),
  };

  const columnsLargeDevice = [...dataColumns, editZoneColumn, deleteZoneColumn];

  const columnsMediumDevice = [
    nameColumn,
    codeColumn,
    editZoneColumn,
    deleteZoneColumn,
  ];

  const columnsSmallDevice = [nameColumn, editZoneColumn, deleteZoneColumn];

  useEffect(() => {
    dispatch(actions.initialZonesWithGeoJson());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      dispatch(actions.zoneWithGeoJsonResetFilters());
    };
  }, [dispatch]);

  const searchCallback = useCallback(
    (searchText) => {
      dispatch(actions.zoneWithGeoJsonUpdateSearch(searchText));
    },
    [dispatch],
  );
  const searcher = {
    onSearching: searchCallback,
    placeholder: 'Nombre',
    allowEmptySearch: true,
  };
  const updatePaginate = useCallback(
    (page) => {
      dispatch(actions.zoneWithGeoJsonUpdatePageable(page));
    },
    [dispatch],
  );
  let filteredZones;
  if (items) {
    filteredZones = items.map((zone) => ({
      key: zone.id,
      code: zone.id,
      name: zone.name,
      detail: zone.detail,
    }));
  }
  return (
    <PaqueryTable
      loading={!loaded}
      header={{
        title: 'Zonas',
        searcher,
        primaryButton: <TableHeader.AddButton url={SITELINKS.zones.add} />,
        secondaryButton: (
          <TableHeader.SecondaryButton
            title="Ver Mapa"
            onClick={() => history.push(SITELINKS.zones.viewAll)}
          />
        ),
        refresh: () => dispatch(actions.refreshPage()),
      }}
      onChangePaginate={updatePaginate}
      dataSource={filteredZones}
      paginate={pageable || DEFAULT_PAGINATE}
      dataColumns={dataColumns}
      colsForSmallDevice={columnsSmallDevice}
      colsForMediumDevice={columnsMediumDevice}
      colsForLargeDevice={columnsLargeDevice}
    />
  );
};

export default ZoneList;
