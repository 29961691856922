import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from 'redux/logout';
import useGlobals from 'redux/globals';
import { selectGlobals } from 'redux/globals/selectors';
import { authProfile } from 'redux/auth/selectors';
import SITELINKS from 'constants/sitelinks';
import { Spin, ConfigProvider } from 'antd';
import {
  ProfileOutlined,
  ShopOutlined,
  ContainerOutlined,
  UserSwitchOutlined,
  UserOutlined,
  BankOutlined,
  ImportOutlined,
  SearchOutlined,
  ExportOutlined,
  RadarChartOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { NavigationLayout } from '@paquery-team/lib-app-layout';
import Logo from 'constants/paquery-logo.png';
import useZones from 'redux/zones';
import PackageList from './packageList';
import PackageWithPaquers from './packageWithPaquers';
import PackageWithPaquersHistorical from './packageWithPaquersHistoric';
import PackageHistory from './packageHistory';
import PackageWithoutZone from './packageWithoutZone';
import PackageView from './packageView';
import PackageMassiveList from './packageMassiveList';
import PackageSearchByCode from './packageSearchByCode';
import ModifyMarketplace from './marketplaceModify';
import MarketplaceList from './marketplaceList';
import AddMarketplace from './marketplaceAdd';
import PaquerList from './paquerList';
import PaqueryPointList from './paquerypointList';
import AddPaqueryPoint from './paquerypointAdd';
import ModifyPaqueryPoint from './paquerypointModify';
import AddPaquer from './paquerAdd';
import ModifyPaquer from './paquerModify';
import PackageModify from './packageModify';
import PackageSend from './packageSend';
import UserProfile from './userProfile';
import UserList from './userList';
import UserEdit from './userEdit';
import UserAdd from './userAdd';
import Zone from './zone';
import ZoneList from './zoneList';
import EntryPacket from './entryPacket';
import PackageDeparture from './packageDeparture';
import PackageDepartureHistory from './packageDepartureHistory';
import Support from './support';

const menuItems = [
  {
    key: 'packagesBySearchCode',
    title: 'Buscar por código',
    icon: <SearchOutlined />,
    link: SITELINKS.packages.bySearchCode,
  },
  {
    key: 'entryPacket',
    title: 'Ingresar',
    icon: <ImportOutlined />,
    link: SITELINKS.packages.entryPacket,
  },
  {
    key: 'packageDeparture',
    title: 'Transferir/Devolver',
    icon: <ExportOutlined />,
    link: SITELINKS.packages.departure,
    subMenuItems: [
      {
        key: 'prepareDeparture',
        title: 'Transferir',
        link: SITELINKS.packages.departure,
      },
      {
        key: 'historicDeparture',
        title: 'Histórico',
        link: SITELINKS.packages.departureHistory,
      },
    ],
  },
  {
    key: 'packages',
    title: 'Paquetes',
    icon: <ContainerOutlined />,
    subMenuItems: [
      {
        key: 'packageList',
        title: 'En transito',
        link: SITELINKS.packages.list,
      },
      {
        key: 'packageHistory',
        title: 'Histórico',
        link: SITELINKS.packages.history,
      },
      {
        key: 'packagesByPaquer',
        title: 'Paquetes por paquer',
        link: SITELINKS.paquers.packagesBy,
      },
      {
        key: 'packagesHistoricalByPaquer',
        title: 'Histórico por paquer',
        link: SITELINKS.paquers.packagesHistorical,
      },
      {
        key: 'packageWithoutZones',
        title: 'Sin zona',
        link: SITELINKS.packages.withoutZone,
      },
      {
        key: 'massivePackageList',
        title: 'Alta masiva',
        link: SITELINKS.massive.list,
      },
      {
        key: 'packageSend',
        title: 'Alta individual',
        link: SITELINKS.packages.send,
      },
    ],
  },
  {
    key: 'administration',
    title: 'Administración',
    icon: <UserOutlined />,
    subMenuItems: [
      {
        key: 'users',
        title: 'Usuarios',
        icon: <UserOutlined />,
        link: SITELINKS.users.list,
      },
      {
        key: 'paquerList',
        title: 'Paquers',
        icon: <UserSwitchOutlined />,
        link: SITELINKS.paquers.list,
      },
      {
        key: 'paquerypoint',
        title: 'PaQuery Points',
        icon: <BankOutlined />,
        link: SITELINKS.paquerypoint.list,
      },
      {
        key: 'marketplace',
        title: 'Marketplaces',
        icon: <ShopOutlined />,
        link: SITELINKS.marketplace.list,
      },
      {
        key: 'zones',
        title: 'Zonas',
        link: SITELINKS.zones.list,
        icon: <RadarChartOutlined />,
      },
    ],
  },
  {
    key: 'profiles',
    title: 'Perfil',
    link: SITELINKS.profiles,
    icon: <ProfileOutlined />,
  },
  {
    key: 'support',
    title: 'Soporte',
    link: SITELINKS.support.index,
    icon: <QuestionCircleOutlined />,
  },
];

const AppRoute = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  useGlobals();
  useZones();
  const profile = useSelector(authProfile);
  const globals = useSelector(selectGlobals);
  if (!globals.loaded) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spin />
      </div>
    );
  }
  const userName = `${profile.name} ${profile.lastName}`;
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgLayout: '#254370',
          colorPrimary: '#0db8b2',
        },
      }}
    >
      <NavigationLayout
        menuItems={menuItems}
        userName={userName}
        logo={Logo}
        logout={() => dispatch(logout())}
        showLangSelector={false}
      >
        <Switch location={location}>
          <Redirect exact from="/" to={SITELINKS.packages.bySearchCode} />
          <Route
            path={SITELINKS.packages.entryPacket}
            render={(props) => <EntryPacket {...props} />}
          />
          <Route
            path={SITELINKS.packages.bySearchCode}
            render={(props) => <PackageSearchByCode {...props} />}
          />
          <Route
            path={SITELINKS.packages.view}
            render={(props) => <PackageView {...props} />}
          />

          <Route
            path={SITELINKS.packages.history}
            render={(props) => <PackageHistory {...props} />}
          />
          <Route
            path={SITELINKS.packages.withoutZone}
            render={(props) => <PackageWithoutZone {...props} />}
          />
          <Route
            path={SITELINKS.massive.list}
            render={(props) => <PackageMassiveList {...props} />}
          />
          <Route
            path={SITELINKS.packages.departureHistory}
            render={(props) => <PackageDepartureHistory {...props} />}
          />
          <Route
            path={SITELINKS.packages.departure}
            render={(props) => <PackageDeparture {...props} />}
          />
          <Route
            path={SITELINKS.packages.send}
            render={(props) => <PackageSend {...props} />}
          />
          <Route
            path={SITELINKS.packages.editById}
            render={(props) => <PackageModify {...props} />}
          />
          <Route
            path={SITELINKS.packages.edit}
            render={(props) => <PackageModify {...props} />}
          />
          <Route
            path={SITELINKS.packages.list}
            render={(props) => <PackageList {...props} />}
          />
          <Route
            path={SITELINKS.paquers.packagesBy}
            render={(props) => <PackageWithPaquers {...props} />}
          />
          <Route
            path={SITELINKS.paquers.packagesHistorical}
            render={(props) => <PackageWithPaquersHistorical {...props} />}
          />
          <Route
            path={SITELINKS.paquers.add}
            render={(props) => <AddPaquer {...props} />}
          />
          <Route
            path={SITELINKS.paquers.edit}
            render={(props) => <ModifyPaquer {...props} />}
          />
          <Route
            path={SITELINKS.paquers.list}
            render={(props) => <PaquerList {...props} />}
          />

          <Route
            path={SITELINKS.paquerypoint.add}
            render={(props) => <AddPaqueryPoint {...props} />}
          />
          <Route
            path={SITELINKS.paquerypoint.edit}
            render={(props) => <ModifyPaqueryPoint {...props} />}
          />
          <Route
            path={SITELINKS.paquerypoint.list}
            render={(props) => <PaqueryPointList {...props} />}
          />

          <Route
            path={SITELINKS.marketplace.add}
            render={(props) => <AddMarketplace {...props} />}
          />
          <Route
            path={SITELINKS.store.edit}
            render={(props) => <ModifyMarketplace {...props} />}
          />
          <Route
            path={SITELINKS.marketplace.edit}
            render={(props) => <ModifyMarketplace {...props} />}
          />
          <Route
            path={SITELINKS.marketplace.list}
            render={(props) => <MarketplaceList {...props} />}
          />

          <Route
            path={SITELINKS.users.add}
            render={(props) => <UserAdd {...props} />}
          />
          <Route
            path={SITELINKS.users.edit}
            render={(props) => <UserEdit {...props} />}
          />
          <Route
            path={SITELINKS.users.list}
            render={(props) => <UserList {...props} />}
          />
          <Route
            path={SITELINKS.zones.add}
            render={(props) => <Zone {...props} />}
          />
          <Route
            path={SITELINKS.zones.edit.route}
            render={(props) => <Zone {...props} />}
          />
          <Route
            path={SITELINKS.zones.viewAll}
            render={(props) => <Zone {...props} />}
          />
          <Route
            path={SITELINKS.zones.list}
            render={(props) => <ZoneList {...props} />}
          />
          <Route
            path={SITELINKS.profiles}
            render={(props) => <UserProfile {...props} />}
          />
          <Route path={SITELINKS.support.index} render={() => <Support />} />
          <Redirect to="/error" />
        </Switch>
      </NavigationLayout>
    </ConfigProvider>
  );
};
export default AppRoute;
