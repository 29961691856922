import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Input, notification } from 'antd';
import Modal from 'components/modal';
import { actions } from 'redux/zones/slice';
import GoogleMap from 'components/maps/GoogleMap';
import { authProfile } from 'redux/auth/selectors';
import ZoneService from 'services/ZoneService';

const { TextArea } = Input;

const ZoneAddSubmit = ({ polygon }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useSelector(authProfile);
  const [name, setName] = useState(null);
  const [detail, setDetail] = useState(null);
  const [visible, setVisible] = useState(false);

  if (!polygon) return null;

  const handleAddZone = async () => {
    try {
      const coordinates = polygon.getCoords();
      const coordinatesConnected = [...coordinates, coordinates[0]];
      const geoJson = {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            coordinatesConnected.map((coords) => [coords.lng, coords.lat]),
          ],
        },
        properties: {},
      };
      const tenant = profile?.tenantId;
      const logisticOperatorId = profile?.logisticOperatorID;

      const zone = {
        detail,
        name,
        geoJson: JSON.stringify(geoJson),
        groupingZone: false,
        logisticOperatorId,
        tenant,
        active: true,
      };
      const response = await ZoneService.create(zone);
      const newZone = response.data;

      notification.success({
        message: 'Creación de zona',
        description: 'Se agregó la zona correctamente a su listado de zonas',
      });
      setVisible(false);
      dispatch(actions.zoneWithGeoJsonUpdateZone(newZone));
    } catch (error) {
      notification.error({
        message: 'Ocurrio un problema',
        description: `Ha ocurrido un error al crear una zona${
          error.message ? `: ${error.message}` : '.'
        }`,
      });
    } finally {
      history.goBack();
    }
  };
  return (
    <>
      <Modal
        title="Nueva Zona"
        onOk={handleAddZone}
        okText="Guardar"
        cancelText="Cancelar"
        onCancel={() => setVisible(false)}
        open={visible}
      >
        <p style={{ marginBottom: '5px' }}>Nombre:</p>
        <Input
          placeholder="Nombre"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <p style={{ marginBottom: '5px', marginTop: '10px' }}>Descripcion:</p>
        <TextArea
          rows={3}
          placeholder="Informacion adicional de esa zona"
          value={detail}
          onChange={(event) => setDetail(event.target.value)}
        />
      </Modal>

      <Button
        type="primary"
        onClick={() => setVisible(true)}
        style={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          marginRight: 69,
          marginBottom: 30,
        }}
      >
        Agregar Zona
      </Button>
    </>
  );
};

ZoneAddSubmit.propTypes = {
  polygon: PropTypes.shape({ getCoords: PropTypes.func.isRequired }),
  zone: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    detail: PropTypes.string,
  }),
};

ZoneAddSubmit.defaultProps = {
  polygon: null,
  zone: null,
};

const Add = ({ resetDrawingComponent: ResetDrawing, zones }) => {
  const [polygon, setPolygon] = useState(null);
  return (
    <GoogleMap
      showDrawing
      resetDrawingComponent={ResetDrawing}
      onPolygonComplete={setPolygon}
      polygons={zones}
      otherComponents={<ZoneAddSubmit polygon={polygon} />}
    />
  );
};
Add.propTypes = {
  resetDrawingComponent: PropTypes.elementType,
  zones: PropTypes.arrayOf(PropTypes.shape({})),
};

Add.defaultProps = {
  resetDrawingComponent: null,
  zones: null,
};

export default Add;
