import Api from 'util/Api';
import API from 'constants/api';

export interface ZoneToCreate {
  detail: string;
  name: string;
  geoJson: string;
  groupingZone: boolean;
  logisticOperatorId: number;
  tenant: number | null | undefined;
  active: boolean;
}

export interface ZoneToUpdate {
  name: string;
  idLegacyZone: string;
  detail: string;
  logisticOperatorId: number;
  active: boolean;
  cdcId: string;
  zipCodes: string[];
  geoJson: string;
  groupingZone: boolean;
  parent: string;
}

/**
 * @description
 * Crea una nueva zona
 */
export const create = async (zone: ZoneToCreate) => {
  try {
    const response = await Api.apiAxios.post(`${API.zones.add}`, zone);
    if (!Api.isSuccessResponse(response)) {
      throw new Error(response.data.message);
    }
    return response;
  } catch (error: any) {
    if (error.isAxiosError) {
      throw error;
    }
    throw new Error(error.message);
  }
};

/**
 * @description
 * Actualiza una zona
 */
export const update = async (id: string, zone: ZoneToUpdate) => {
  try {
    const response = await Api.apiAxios.put(
      `${API.zones.updateById(id)}`,
      zone,
    );
    if (!Api.isSuccessResponse(response)) {
      throw new Error(response.data.message);
    }
    return response;
  } catch (error: any) {
    if (error.isAxiosError) {
      throw error;
    }
    throw new Error(error.message);
  }
};

/**
 * @description
 * Elimina una zona
 */
export const deleteById = async (id: string | null) => {
  if (!id) throw new Error('No se pudo obtener el ID para eliminar la zona');
  try {
    const response = await Api.apiAxios.delete(`${API.zones.deleteById(id)}`);
    if (!Api.isSuccessResponse(response)) {
      throw new Error(response.data.message);
    }
    return response;
  } catch (error: any) {
    if (error.isAxiosError && error.response.data.message) {
      throw new Error(error.response.data.message);
    }
    throw new Error(error.message);
  }
};

export default {
  create,
  deleteById,
  update,
};
