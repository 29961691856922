import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Card, theme } from 'antd';
import { DEFAULT_DRAWING_MODE, POLYGON_COLORS } from 'constants/defaultValues';
import { GOOGLE_MAPS_API_KEY } from 'constants/api';
import {
  Map,
  GoogleApiWrapper,
  Marker,
  InfoWindow,
  Polyline,
} from 'google-maps-react';

const mapStyle = {
  width: '100%',
  height: '100%',
  margin: 0,
};

const useGoogleMapsPositionHelpers = (google) => {
  useEffect(() => {
    if (google) {
      google.maps.Polygon.prototype.getBoundingBox = function () {
        const bounds = new google.maps.LatLngBounds();
        this.getPath().forEach((element) => {
          bounds.extend(element);
        });
        return bounds;
      };
      google.maps.Polygon.prototype.getApproximateCenter = function () {
        let boundsHeight = 0;
        let boundsWidth = 0;
        let centerPoint;
        let heightIncr = 0;
        const maxSearchSteps = 10;
        let n = 1;
        let northWest;
        const polygonBounds = this.getBoundingBox();
        let testPos;
        let widthIncr = 0; // Get polygon Centroid
        centerPoint = polygonBounds.getCenter();
        if (google.maps.geometry.poly.containsLocation(centerPoint, this)) {
          // Nothing to do Centroid is in polygon use it as is
          return centerPoint;
        }
        // height of polygon NW->SE
        northWest = new google.maps.LatLng(
          polygonBounds.getNorthEast().lat(),
          polygonBounds.getSouthWest().lng(),
        ); // Work out how tall and wide the bounds are and what our search
        // increment will be
        boundsHeight = google.maps.geometry.spherical.computeDistanceBetween(
          northWest,
          polygonBounds.getSouthWest(),
        );
        heightIncr = boundsHeight / maxSearchSteps;
        boundsWidth = google.maps.geometry.spherical.computeDistanceBetween(
          northWest,
          polygonBounds.getNorthEast(),
        );
        widthIncr = boundsWidth / maxSearchSteps; // Expand out from Centroid and find a point within polygon at
        // 0, 90, 180, 270 degrees
        for (; n <= maxSearchSteps; n++) {
          // Test point North of Centroid
          testPos = google.maps.geometry.spherical.computeOffset(
            centerPoint,
            heightIncr * n,
            0,
          );
          if (google.maps.geometry.poly.containsLocation(testPos, this)) {
            break;
          } // Test point East of Centroid
          testPos = google.maps.geometry.spherical.computeOffset(
            centerPoint,
            widthIncr * n,
            90,
          );
          if (google.maps.geometry.poly.containsLocation(testPos, this)) {
            break;
          } // Test point South of Centroid
          testPos = google.maps.geometry.spherical.computeOffset(
            centerPoint,
            heightIncr * n,
            180,
          );
          if (google.maps.geometry.poly.containsLocation(testPos, this)) {
            break;
          } // Test point West of Centroid
          testPos = google.maps.geometry.spherical.computeOffset(
            centerPoint,
            widthIncr * n,
            270,
          );
          if (google.maps.geometry.poly.containsLocation(testPos, this)) {
            break;
          }
        }
        return testPos;
      };
    }
  }, [google]);
};

const defaultDisabledPolygon = {
  strokeColor: '#999',
  strokeOpacity: 0.7,
  strokeWeight: 3,
  fillColor: '#999',
  fillOpacity: 0.4,
};

const addPolygon = ({
  google,
  map,
  path,
  detail,
  renderOptions = defaultDisabledPolygon,
}) => {
  const polygonOverlay = new google.maps.Polygon({
    path,
    ...renderOptions,
  });
  polygonOverlay.detail = detail;
  polygonOverlay.setMap(map);
  return polygonOverlay;
};

const MapContainer = ({
  google,
  markers,
  initialCenter,
  zoom,
  showDrawing,
  showEditPolygon,
  defaultDrawingMode,
  polygons,
  clickablePolygons,
  editablePolygon,
  resetDrawingComponent: ResetDrawingComponent,
  onPolygonComplete,
  otherComponents,
}) => {
  const { token } = theme.useToken();
  const mapRef = useRef(null);
  const [polygon, setPolygon] = useState(null);
  const [selectedPolygon, setSelectedPolygon] = useState(null);
  const [polygonClickListeners, setPolygonClickListeners] = useState(null);
  const [polygonsDrawed, setPolygonsDrawed] = useState([]);
  const [geometryLoaded, setGeometryLoaded] = useState(false);
  const [drawingTool, setDrawingTool] = useState(null);
  const [bounds, setBounds] = useState(null);
  const [routeLines, setRouteLines] = useState(null);
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [activeMarker, setActiveMarker] = useState({});
  const [addressDetailComponent, setAddressDetailComponent] = useState(null);
  const drawedMarkers = [];
  useGoogleMapsPositionHelpers(google);
  useEffect(() => {
    if (markers?.length > 0) {
      const newBounds = new google.maps.LatLngBounds();
      const firstAddress = markers[0];
      newBounds.extend({ lat: firstAddress.lat, lng: firstAddress.lng });
      const lastAddress = markers.slice(-1).pop();
      newBounds.extend({ lat: lastAddress.lat, lng: lastAddress.lng });
      setBounds(newBounds);
    }
  }, [markers, google.maps.LatLngBounds]);

  const addingEditingPolygonStyle = {
    editable: true,
    strokeColor: token.colorPrimary,
    strokeWeight: 2,
    strokeOpacity: 0.4,
    fillColor: token.colorPrimary,
    fillOpacity: 0.2,
  };

  useEffect(() => {
    if (markers?.length > 0) {
      setRouteLines(
        <Polyline
          path={markers.map((marker) => ({ lat: marker.lat, lng: marker.lng }))}
          strokeColor={token.colorPrimary}
          strokeOpacity={0.8}
          strokeWeight={4}
        />,
      );
    }
  }, [markers]);

  useEffect(() => {
    if (geometryLoaded && polygonsDrawed?.length > 0) {
      const { map } = mapRef.current;
      for (
        let polygonIndex = 0;
        polygonIndex < polygonsDrawed.length;
        polygonIndex += 1
      ) {
        const currentDrawedPolygon = polygonsDrawed[polygonIndex];
        const centerPosition = currentDrawedPolygon.getApproximateCenter();
        const marker = new google.maps.Marker({
          position: {
            lat: centerPosition.lat(),
            lng: centerPosition.lng(),
          },
          label: {
            text: currentDrawedPolygon.detail,
            color: '#438289',
            fontWeight: 'bold',
            fontSize: '20px',
          },
          icon: {
            path: google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
            strokeOpacity: 0,
          },
        });
        marker.setVisible(map.getZoom() >= 9);
        marker.setMap(map);
        drawedMarkers.push(marker);
        google.maps.event.addListener(map, 'zoom_changed', () => {
          // iterate over markers and call setVisible
          drawedMarkers.forEach((drawedMarker) => {
            drawedMarker.setVisible(map.getZoom() >= 9);
          });
        });
      }
    }
  }, [geometryLoaded, polygonsDrawed, google, drawedMarkers]);

  useEffect(() => {
    if (google.maps.geometry) {
      setGeometryLoaded(true);
    }
  }, [google.maps.geometry]);

  useEffect(() => {
    if (polygonsDrawed?.length > 0 && clickablePolygons && !selectedPolygon) {
      const clickListeners = [];
      for (
        let polygonDrawIndex = 0;
        polygonDrawIndex < polygonsDrawed.length;
        polygonDrawIndex += 1
      ) {
        const currentPolygonDrawed = polygonsDrawed[polygonDrawIndex];
        const listener = google.maps.event.addListener(
          currentPolygonDrawed,
          'click',
          (e) => {
            setSelectedPolygon(currentPolygonDrawed);
          },
        );
        clickListeners.push(listener);
      }
      setPolygonClickListeners(clickListeners);
    }
  }, [polygonsDrawed, clickablePolygons, selectedPolygon, google.maps.event]);

  useEffect(() => {
    if (selectedPolygon) {
      selectedPolygon.setEditable(true);
      selectedPolygon.setOptions(addingEditingPolygonStyle);
      for (
        let listenerIndex = 0;
        listenerIndex < polygonClickListeners.length;
        listenerIndex += 1
      ) {
        const listener = polygonClickListeners[listenerIndex];
        google.maps.event.removeListener(listener);
      }
    }
  }, [selectedPolygon, polygonClickListeners, google.maps.event]);

  useEffect(() => {
    if (polygons?.length > 0) {
      const { map } = mapRef.current;
      const polygonsRendered = [];
      let colourIndex = 0;
      for (
        let polygonIndex = 0;
        polygonIndex < polygons.length;
        polygonIndex += 1
      ) {
        colourIndex = colourIndex === POLYGON_COLORS.length ? 0 : colourIndex;
        const currentPolygon = polygons[polygonIndex];
        const newPolygon = addPolygon({
          google,
          map,
          path: currentPolygon.path,
          detail: currentPolygon.detail,
          renderOptions: {
            strokeColor: POLYGON_COLORS[colourIndex],
            fillColor: POLYGON_COLORS[colourIndex],
          },
        });
        polygonsRendered.push(newPolygon);
        colourIndex += 1;
      }
      setPolygonsDrawed(polygonsRendered);
    }
  }, [polygons, google]);
  const addEditablePolygon = useCallback(
    (poly) => {
      const { map } = mapRef.current;
      const newPolygon = addPolygon({
        google,
        map,
        path: poly.path,
        detail: poly.detail,
        renderOptions: addingEditingPolygonStyle,
      });

      newPolygon.getCoords = () =>
        newPolygon
          .getPath()
          .getArray()
          .map((point) => ({ lat: point.lat(), lng: point.lng() }));
      if (onPolygonComplete) {
        onPolygonComplete(newPolygon);
      }
      setPolygon(newPolygon);
    },
    [onPolygonComplete, google],
  );

  useEffect(() => {
    const getPolygonBounds = (poly) => {
      const newBounds = new google.maps.LatLngBounds();
      for (
        let polygonCoordIndex = 0;
        polygonCoordIndex < poly.path.length;
        polygonCoordIndex += 1
      ) {
        const coordinates = poly.path[polygonCoordIndex];
        newBounds.extend({ lat: coordinates.lat, lng: coordinates.lng });
      }
      setBounds(newBounds);
    };
    if (editablePolygon) {
      addEditablePolygon(editablePolygon);
      getPolygonBounds(editablePolygon);
    }
  }, [editablePolygon, addEditablePolygon, google]);

  useEffect(() => {
    if (drawingTool) {
      const drawingModes = [];
      if (showEditPolygon) {
        drawingModes.push(google.maps.drawing.OverlayType.POLYGON);
      }
      drawingTool.setOptions({
        drawingControlOptions: {
          drawingModes,
          position: google.maps.ControlPosition.TOP_CENTER,
        },
      });
    }
  }, [drawingTool, showEditPolygon, google.maps]);

  const getDefaultDrawingMode = () => {
    switch (defaultDrawingMode) {
      case DEFAULT_DRAWING_MODE.POLYGON:
        return google.maps.drawing.OverlayType.POLYGON;
      case DEFAULT_DRAWING_MODE.HAND:
      default:
        return null;
    }
  };

  const onReady = () => {
    if (showDrawing) {
      const { map } = mapRef.current;
      const drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: getDefaultDrawingMode(),
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [],
        },
        polygonOptions: addingEditingPolygonStyle,
      });

      google.maps.event.addListener(
        drawingManager,
        'polygoncomplete',
        (polygon) => {
          polygon.getCoords = () =>
            polygon
              .getPath()
              .getArray()
              .map((point) => ({ lat: point.lat(), lng: point.lng() }));
          setPolygon(polygon);
          if (onPolygonComplete) {
            onPolygonComplete(polygon);
          }
          drawingManager.setMap(null);
        },
      );
      drawingManager.setMap(map);
      setDrawingTool(drawingManager);
    }
  };
  const onMapClicked = () => {
    if (showingInfoWindow) {
      setShowingInfoWindow(false);
      setActiveMarker(null);
    }
  };
  const handleResetDrawingClick = () => {
    if (drawingTool && polygon) {
      const { map } = mapRef.current;
      polygon.setMap(null);
      if (onPolygonComplete) {
        onPolygonComplete(null);
      }
      drawingTool.setMap(null);
      drawingTool.setMap(map);
      if (editablePolygon) {
        addEditablePolygon(editablePolygon);
      }
      drawingTool.setDrawingMode(getDefaultDrawingMode());
    }
  };
  const packetIcon = {
    path: 'M 125,5 155,90 245,90 175,145 200,230 125,180 50,230 75,145 5,90 95,90 z',
    fillColor: 'yellow',
    fillOpacity: 0.8,
    scale: 1,
    strokeColor: 'gold',
    strokeWeight: 14,
  };
  return (
    <Card
      bodyStyle={{ padding: 0 }}
      style={{ overflow: 'hidden', minHeight: '85vh' }}
    >
      <Map
        google={google}
        zoom={zoom}
        onClick={onMapClicked}
        onReady={onReady}
        style={mapStyle}
        initialCenter={initialCenter}
        bounds={bounds}
        ref={mapRef}
      >
        {markers.map((address) => (
          <Marker
            position={{ lat: address.lat, lng: address.lng }}
            onClick={(_, marker) => {
              setAddressDetailComponent(address.detailComponent);
              setActiveMarker(marker);
              setShowingInfoWindow(true);
            }}
            title={address.name}
            icon={{
              icon: packetIcon,
              anchor: new google.maps.Point(33, 32),
              scaledSize: new google.maps.Size(64, 64),
            }}
          />
        ))}
        <InfoWindow marker={activeMarker} visible={showingInfoWindow}>
          {addressDetailComponent}
        </InfoWindow>
        {routeLines}
        <ResetDrawingComponent onResetDrawing={handleResetDrawingClick} />
        {otherComponents}
      </Map>
    </Card>
  );
};

MapContainer.propTypes = {
  google: PropTypes.shape({}).isRequired,
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
      detailComponent: PropTypes.elementType,
      name: PropTypes.string.isRequired,
    }),
  ),
  initialCenter: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }),
  showDrawing: PropTypes.bool,
  defaultDrawingMode: PropTypes.number,
  showEditPolygon: PropTypes.bool,
  zoom: PropTypes.number,
  otherComponents: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.elementType),
    PropTypes.elementType,
  ]),
  polygons: PropTypes.arrayOf(PropTypes.shape({})),
  clickablePolygons: PropTypes.bool,
  editablePolygon: PropTypes.bool,
  resetDrawingComponent: PropTypes.elementType,
  onPolygonComplete: PropTypes.func,
};

MapContainer.defaultProps = {
  initialCenter: {
    lat: -34.601692,
    lng: -58.431461,
  },
  markers: [],
  showEditPolygon: true,
  showDrawing: false,
  defaultDrawingMode: DEFAULT_DRAWING_MODE.POLYGON,
  zoom: 7,
  otherComponents: null,
  polygons: [],
  clickablePolygons: false,
  editablePolygon: false,
  resetDrawingComponent: null,
  onPolygonComplete: null,
};

export default GoogleApiWrapper({
  apiKey: GOOGLE_MAPS_API_KEY,
  libraries: ['places', 'drawing', 'geometry'],
  version: '3.44.4',
})(MapContainer);
