import React from 'react';
import PropTypes from 'prop-types';
import GoogleMap from 'components/maps/GoogleMap';
import { DEFAULT_DRAWING_MODE } from 'constants/defaultValues';

const Show = ({ resetDrawingComponent: ResetDrawing, polygonsFromZones }) => (
  <GoogleMap
    showDrawing
    resetDrawingComponent={ResetDrawing}
    defaultDrawingMode={DEFAULT_DRAWING_MODE.HAND}
    showEditPolygon={false}
    polygons={polygonsFromZones}
  />
);

Show.propTypes = {
  resetDrawingComponent: PropTypes.elementType,
  polygonsFromZones: PropTypes.arrayOf(PropTypes.shape({})),
};

Show.defaultProps = {
  resetDrawingComponent: null,
  polygonsFromZones: null,
};

export default Show;
