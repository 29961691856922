import { createSlice } from '@reduxjs/toolkit';
import { clearReducers } from 'redux/logout';

export const initialState = {
  items: {},
  zones: {
    items: [],
    pageable: {
      pageNumber: 0,
      pageSize: 10,
    },
    search: '',
    loaded: false,
  },
  zonesWithGeoJson: {
    items: [],
    search: '',
    pageable: {
      pageNumber: 0,
      pageSize: 10,
    },
    loaded: false,
  },
};

const zoneSlice = createSlice({
  name: 'zones',
  initialState,
  reducers: {
    initial: () => {},
    fetch: (state) => {
      state.zones.loaded = false;
    },
    success: (state, action) => {
      state.zones.loaded = true;
      state.zones.items = action.payload;
    },
    failure: (state) => {
      state.zones.loaded = false;
    },
    initialZonesWithGeoJson: () => {},
    fetchZonesWithGeoJson: (state) => {
      state.zonesWithGeoJson.loaded = false;
    },
    successFetchZonesWithGeoJson: (state, action) => {
      state.zonesWithGeoJson.loaded = true;
      state.zonesWithGeoJson.items = action.payload;
    },
    failureFetchZonesWithGeoJson: (state) => {
      state.zonesWithGeoJson.loaded = true;
    },
    //
    zoneWithGeoJsonUpdatePageable: (state, action) => {
      state.zonesWithGeoJson.pageable.pageNumber =
        action.payload.pageSize !== state.zonesWithGeoJson.pageable.pageSize
          ? 0
          : action.payload.pageNumber;
      state.zonesWithGeoJson.pageable.pageSize = action.payload.pageSize;
    },
    zoneWithGeoJsonUpdateSearch: (state, action) => {
      state.zonesWithGeoJson.pageable.pageNumber = 0;
      state.zonesWithGeoJson.search = action.payload;
    },
    zoneWithGeoJsonResetFilters: (state) => {
      state.zonesWithGeoJson.search = initialState.zonesWithGeoJson.search;
      state.zonesWithGeoJson.pageable = initialState.zonesWithGeoJson.pageable;
    },
    zoneWithGeoJsonUpdateZone: (state, action) => {
      state.zonesWithGeoJson.items[action.payload.id] = action.payload;
    },
    zoneWithGeoJsonRemoveZone: (state, action) => {
      delete state.zonesWithGeoJson.items[action.payload];
    },
    //
    updatePageable: (state, action) => {
      state.zones.pageable.pageNumber =
        action.payload.pageSize !== state.zones.pageable.pageSize
          ? 0
          : action.payload.pageNumber;
      state.zones.pageable.pageSize = action.payload.pageSize;
    },
    updateSearch: (state, action) => {
      state.zones.pageable.pageNumber = 0;
      state.zones.search = action.payload;
    },
    resetFilters: (state) => {
      state.zones.search = initialState.zones.search;
      state.zones.pageable = initialState.zones.pageable;
    },
    updateZone: (state, action) => {
      state.zones.items[action.payload.id] = action.payload;
    },
    removeZone: (state, action) => {
      delete state.zones.items[action.payload];
    },
    refreshPage: () => {},
  },
  extraReducers: {
    [clearReducers]: () => initialState,
  },
});

export const { name, reducer, actions } = zoneSlice;
