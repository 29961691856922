import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import usePaquerypointView from 'redux/paquerypointView';
import { actions } from 'redux/paquerypointView/slice';
import { actions as paqueryPointActions } from 'redux/paquerypoint/slice';
import {
  paquerypointViewLoadingSelector,
  paquerypointViewPaquerypointSelector,
} from 'redux/paquerypointView/selectors';
import {
  countriesGlobalsSelector,
  currentServicesGlobalsSelector,
  daysServiceGlobalsSelector,
} from 'redux/globals/selectors';
import * as Yup from 'yup';
import { notification, Tabs } from 'antd';
import rest, { getData } from 'util/Api';
import API from 'constants/api';
import { zoneItemsSelector } from 'redux/zones/selectors';
import PaqueryPoint from '../paquerypoint';
import PaqueryPointPackages from './components/packages';
import PaqueryPointHistory from './components/history';
import ArrivePacket from './components/arrive';

const { TabPane } = Tabs;

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Indique un nombre de paquery point'),
  address: Yup.string().required('Indique la dirección del paquery point'),
  phone: Yup.number()
    .required('El teléfono no puede ir vacío')
    .typeError('Solo deben ir numeros y el campo es requerido'),
  detail: Yup.string().required(
    'Es necesario indicar una descripción del paquery point',
  ),
  contactName: Yup.string().required(
    'Es necesario indicar un nombre de contacto',
  ),
  volume: Yup.number()
    .typeError('Deben ser números y son requeridos')
    .required('Es necesario indicar el volumen')
    .min(1, 'No puede ser 0'),
  currentServices: Yup.array().min(1, 'Es necesario indicar un servicio'),
  zones: Yup.array().min(1, 'Ingrese al menos una zona de trabajo'),
  daysOfWeek: Yup.array().min(1, 'Ingrese al menos un día de trabajo'),
});

const initialValues = {
  name: '',
  address: '',
  phone: '',
  detail: '',
  contactName: '',
  volume: 0,
  currentServices: [],
  daysOfWeek: [],
  zones: [],
  paqueryPoints: [],
  countryID: 1,
  initHour: 9,
  finishHour: 18,
  postalCode: '',
  published: false,
  private: false,
  active: false,
  openBranchOffice: false,
};

const ModifyPaqueryPoint = () => {
  usePaquerypointView();
  const dispatch = useDispatch();
  const loading = useSelector(paquerypointViewLoadingSelector);
  const paquerypoint = useSelector(paquerypointViewPaquerypointSelector);
  const currentServices = useSelector(currentServicesGlobalsSelector);
  const daysOfWeek = useSelector(daysServiceGlobalsSelector);
  const countries = useSelector(countriesGlobalsSelector);
  const zones = useSelector(zoneItemsSelector);
  const [currentCity, setCurrentCity] = useState(null);
  const [PPointData, setPPointData] = useState(initialValues);
  const [cities, setCities] = useState([]);
  const history = useHistory();
  const { paquerypointId } = useParams();

  useEffect(() => {
    if (paquerypointId) {
      dispatch(actions.fetchPaquerypoint(paquerypointId));
    }
  }, [paquerypointId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(actions.reset());
    };
  }, [dispatch]);

  useEffect(() => {
    const fetchCurrentPPoint = async () => {
      dispatch(actions.loading());
      const payload = {
        id: paquerypoint.id,
        name: paquerypoint.name,
        address: paquerypoint.address,
        phone: paquerypoint.phone,
        detail: paquerypoint.detail,
        contactName: paquerypoint.contactName,
        volume: paquerypoint.volume,
        paqueryPoints: paquerypoint.paqueryPoints,
        currentServices: currentServices.filter((currServices) =>
          paquerypoint.currentServices?.find(
            (PPointService) => currServices.value === PPointService,
          ),
        ),
        daysOfWeek: daysOfWeek.filter((dayOfWeek) =>
          paquerypoint.workingScheduleTimes[0]?.daysOFWeek.find(
            (dayOfWeekPPoint) => dayOfWeekPPoint === dayOfWeek.value,
          ),
        ),
        // Reemplazar 'allZones' con 'zones'
        zones: zones.filter(
          (zone) =>
            zone?.id &&
            paquerypoint.zones.find((zonePP) => zonePP.id === zone.id),
        ),
        ownerLogisticOperatorId: paquerypoint.ownerLogisticOperatorId,
        countryID: paquerypoint.locationAddress.countryID,
        cityID: paquerypoint.locationAddress.cityID,
        workingScheduleTimesID: paquerypoint.workingScheduleTimes[0].id,
        initHour: paquerypoint.workingScheduleTimes[0].initHour,
        finishHour: paquerypoint.workingScheduleTimes[0].finishHour,
        postalCode: paquerypoint.locationAddress.postalCode,
        published: paquerypoint.published,
        private: paquerypoint.private,
        active: paquerypoint.active,
        openBranchOffice: paquerypoint.openBranchOffice,
      };
      const citiesResponse = await getData(
        `${API.globals.cities}/${paquerypoint.locationAddress.countryID}`,
      );
      setCities(citiesResponse);
      setPPointData(payload);
      dispatch(actions.loaded());
    };
    if (currentServices && paquerypoint) {
      fetchCurrentPPoint();
    }
  }, [paquerypoint, currentServices, dispatch, zones, daysOfWeek]);

  const selectCountry = useCallback(
    async (country) => {
      dispatch(actions.loading());
      const citiesResponse = await getData(`${API.globals.cities}/${country}`);
      setCities(citiesResponse);
      if (citiesResponse.length > 0) {
        setCurrentCity(citiesResponse[0].id);
      } else {
        setCurrentCity(null);
      }
      dispatch(actions.loaded());
    },
    [dispatch],
  );

  const handleSubmit = async (values) => {
    dispatch(actions.loading());
    const selectedCity = cities.find((city) => city.id === values.cityID);
    const selectedCountry = countries.find(
      (country) => country.id === values.countryID,
    );

    const payload = {
      id: values.id,
      active: values.active,
      contactName: values.contactName,
      country: values.countryID,
      currentServices: values.currentServices.map((service) => service.value),
      detail: values.detail,
      locationAddress: {
        address: values.address,
        city: {
          dialingCode: null,
          externalPlaceID: null,
          externalRefernceID: null,
          geoKey: '',
          id: selectedCity.id,
          name: selectedCity.name,
          twoLetterISOCode: selectedCity,
        },
        cityID: values.cityID,
        country: {
          dialingCode: selectedCountry.dialingCode,
          geoKey: '',
          id: selectedCountry.id,
          name: selectedCountry.name,
          twoLetterISOCode: selectedCountry.twoLetterISOCode,
        },
        id: values.id,
        countryID: values.countryID,
        postalCode: values.postalCode,
      },
      name: values.name,
      openBranchOffice: values.openBranchOffice,
      private: values.private,
      ownerLogisticOperatorId: values.ownerLogisticOperatorId,
      phone: values.phone,
      published: values.published,
      paqueryPoints: values.paqueryPoints,
      volume: values.volume,
      workingScheduleTimes: [
        {
          daysOfWeek: values.daysOfWeek.map((day) => day.value),
          finishHour: values.finishHour,
          id: values.workingScheduleTimesID,
          initHour: values.initHour,
        },
      ],
      zones: values.zones,
    };

    try {
      const response = await rest.put(
        `${API.paqueryPoint.update}/${values.id}`,
        payload,
      );
      if (rest.isSuccessResponse(response)) {
        notification.success({
          message: 'Actualización de paquerypoint',
          description: `El paquerypoint ${values.name} ha sido actualizado correctamente.`,
        });
        dispatch(paqueryPointActions.updatePaqueryPoint(payload));
        setTimeout(() => {
          history.push('/paquerypoints');
        }, 1000);
      }
    } catch (error) {
      dispatch(actions.loaded());
    }
  };

  return (
    <Tabs defaultActiveKey="detail" size="large" centered>
      <TabPane tab="Detalles" key="detail" style={{ textAlign: 'left' }}>
        <PaqueryPoint
          loaded={!loading}
          onSubmit={handleSubmit}
          initialValues={PPointData}
          validationSchema={validationSchema}
          daysOfWeek={daysOfWeek}
          currentServices={currentServices}
          countries={countries}
          setCurrentCountry={selectCountry}
          cities={cities}
          currentCity={currentCity}
        />
      </TabPane>
      <TabPane tab="Arribar" key="arrive">
        <ArrivePacket />
      </TabPane>
      <TabPane tab="Paquetes" key="packages">
        <PaqueryPointPackages />
      </TabPane>
      <TabPane tab="Historial" key="history">
        <PaqueryPointHistory />
      </TabPane>
    </Tabs>
  );
};

export default ModifyPaqueryPoint;
