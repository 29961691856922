import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, BrowserRouter } from 'react-router-dom';
import Login from 'routes/login';
import AppRoute from 'routes/app';
import useAuth from 'redux/auth';
import { actions } from 'redux/auth/slice';
import { authTokenSelector } from 'redux/auth/selectors';
import { loadKey } from 'util/localstorage';
import SITELINKS from 'constants/sitelinks';

const LoggedRoute = ({ token, ...otherProps }) => (
  <Route
    {...otherProps}
    render={(props) =>
      token ? <AppRoute {...props} /> : <Redirect to={SITELINKS.login.index} />
    }
  />
);

LoggedRoute.propTypes = {
  token: PropTypes.string,
};

LoggedRoute.defaultProps = {
  token: null,
};

const PublicRoute = ({
  component: ComponentToRender,
  token,
  ...otherProps
}) => (
  <Route
    {...otherProps}
    render={(props) =>
      token ? (
        <Redirect to={SITELINKS.packages.arrivePacket} />
      ) : (
        <ComponentToRender {...props} />
      )
    }
  />
);

PublicRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  token: PropTypes.string,
};

PublicRoute.defaultProps = {
  token: null,
};

const App = () => {
  useAuth();
  const [isTokenChecked, setIsTokenChecked] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector(authTokenSelector);
  useEffect(() => {
    if (!token) {
      const tokenOnLocalStorage = loadKey('sessionToken');
      if (tokenOnLocalStorage) {
        dispatch(actions.rehydrate(tokenOnLocalStorage));
        dispatch(actions.fetchLoggedUser());
      }
    }
    setIsTokenChecked(true);
  }, [token, dispatch]);

  if (!isTokenChecked) {
    return null;
  }
  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute
          path={SITELINKS.login.index}
          token={token}
          component={Login}
          exact
        />
        <PublicRoute
          path={SITELINKS.login.recover}
          token={token}
          component={Login}
          exact
        />
        <PublicRoute
          path={SITELINKS.login.confirm}
          token={token}
          component={Login}
          exact
        />
        <LoggedRoute path="/" token={token} />
        <Redirect to="" />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
