import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import dayjs from 'dayjs';
import { notification } from 'antd';
import API from 'constants/api';
import { getData } from 'util/Api';
import { authProfile } from 'redux/auth/selectors';
import { actions as profileActions } from 'redux/auth/slice';
import { logout } from '../logout';
import { actions } from './slice';
import { selectGlobals } from './selectors';

function* fetchGlobalsSaga({ payload }) {
  const countryID = payload;
  try {
    const {
      packageType,
      packageSize,
      packageStatus,
      deliveryPackageType,
      notifierType,
      countries,
      cities,
      roles,
      deliveryTerm,
      logisticOperator,
      userType,
      daysService,
      currentServices,
      dispatchTypes,
      // vehicleTypes,
    } = yield all({
      packageType: call(getData, API.globals.packageType),
      packageSize: call(getData, API.globals.packageSize),
      packageStatus: call(getData, API.globals.packageStatus),
      deliveryPackageType: call(getData, API.globals.deliveryPackageType),
      notifierType: call(getData, API.globals.notifierType),
      countries: call(getData, API.globals.countries),
      cities: call(getData, `${API.globals.cities}/${countryID}`),
      roles: call(getData, API.globals.roles),
      deliveryTerm: call(getData, API.globals.deliveryTerm),
      logisticOperator: call(getData, API.globals.logisticOperator),
      userType: call(getData, API.globals.userType),
      daysService: call(getData, API.globals.daysOfWeek),
      currentServices: call(getData, API.globals.currentServices),
      dispatchTypes: call(getData, API.globals.dispatchTypes),
      // vehicleTypes: call(getData, API.globals.vehicleTypes),
    });
    const packages = {
      type: packageType,
      size: packageSize,
      status: packageStatus,
      deliveryEndorsementType: deliveryPackageType,
    };
    const globals = {
      packages,
      countries,
      roles,
      deliveryTerm,
      notifierType,
      logisticOperator,
      cities,
      userType,
      daysService,
      currentServices,
      dispatchTypes,
      vehicleTypes: [
        { id: 1, name: 'Moto' },
        { id: 2, name: 'Auto' },
        { id: 3, name: 'Utilitario' },
        { id: 5, name: 'Utilitario Grande' },
      ],
    };
    yield put({ type: actions.success.type, payload: globals });
  } catch (error) {
    notification.error({
      message: 'Error iniciando la aplicacion',
      description: `Hubo un error llamando a los servicios, intente nuevamente o contacte a soporte - ${error.message}`,
      duration: 20,
    });
    yield put({ type: logout.type });
    yield put({ type: actions.failure.type });
  }
}

function* getZoneinfo() {
  const { countries, cities } = yield select(selectGlobals);
  const { countryID, cityID } = yield select(authProfile);
  let { timeZone } = cities.find((c) => c.id === cityID);
  if (!timeZone) timeZone = countries.find((c) => c.id === countryID).timeZone;
  if (!timeZone) timeZone = 'America/Buenos_Aires';
  dayjs.tz.setDefault(timeZone);
  yield put({ type: profileActions.setZoneinfo, payload: timeZone });
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.fetch.type, fetchGlobalsSaga),
    yield takeLatest(actions.success.type, getZoneinfo),
  ]);
}
