import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { Button, Input, notification } from 'antd';
import GoogleMap from 'components/maps/GoogleMap';
import { DEFAULT_DRAWING_MODE } from 'constants/defaultValues';
import SITELINKS from 'constants/sitelinks';
import { zoneWithGeoJsonSelector } from 'redux/zones/selectors';
import { actions } from 'redux/zones/slice';
import ZoneService from 'services/ZoneService';
import Modal from 'components/modal';

const useZoneModalStyles = createUseStyles({
  body: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '35px 1fr',
    rowGap: '15px',
    columnGap: '15px',
    textAlign: 'center',
  },
});

const { TextArea } = Input;

const ZoneEditSubmit = ({ polygon, zone }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useZoneModalStyles();
  const [name, setName] = useState(null);
  const [detail, setDetail] = useState(null);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (zone) {
      setName(zone.name);
      setDetail(zone.detail);
    }
  }, [zone]);
  if (!polygon) return null;

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const coordinates = polygon.getCoords();
      const coordinatesConnected = [...coordinates, coordinates[0]];
      const geoJson = {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            coordinatesConnected.map((coords) => [coords.lng, coords.lat]),
          ],
        },
        properties: {},
      };

      const zoneToCreate = {
        ...zone,
        name,
        detail,
        geoJson: JSON.stringify(geoJson),
      };

      await ZoneService.update(zone.id, zoneToCreate);

      notification.success({
        message: 'Edición de zona',
        description: 'Se confirmó la edición de la zona correctamente.',
      });
      setVisible(false);
      dispatch(actions.refreshPage());
      setTimeout(() => {
        history.goBack();
      }, 1500);
      // we dont add setLoading(false) because we are redirecting
    } catch (error) {
      notification.error({
        message: 'Ocurrio un problema',
        description: `Ha ocurrido un error al editar una zona${
          error.message ? `: ${error.message}` : '.'
        }`,
      });
      setLoading(false);
    }
  };
  return (
    <>
      <Modal
        title="Editar Zona"
        onOk={handleSubmit}
        okText="Guardar"
        cancelText="Cancelar"
        onCancel={() => setVisible(false)}
        confirmLoading={loading}
        open={visible}
      >
        <div className={classes.body}>
          <span style={{ placeSelf: 'center end' }}>Nombre:</span>
          <Input
            placeholder="Nombre"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
          <span style={{ placeSelf: 'center end' }}>Descripcion:</span>
          <TextArea
            rows={3}
            placeholder="Informacion adicional de esa zona"
            value={detail}
            onChange={(event) => setDetail(event.target.value)}
          />
        </div>
      </Modal>

      <Button
        type="primary"
        onClick={() => setVisible(true)}
        style={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          marginRight: 69,
          marginBottom: 30,
        }}
        loading={loading}
      >
        Guardar Cambios
      </Button>
    </>
  );
};

ZoneEditSubmit.propTypes = {
  polygon: PropTypes.shape({ getCoords: PropTypes.func.isRequired }),
  zone: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    detail: PropTypes.string,
  }),
};

ZoneEditSubmit.defaultProps = {
  polygon: null,
  zone: null,
};

const useGetZone = (zones) => {
  const history = useHistory();
  const [zone, setZone] = useState(null);
  const { id } = useParams();
  useEffect(() => {
    const allInfoZones = Object.values(zones);
    const numberID = parseInt(id, 10);
    if (id && allInfoZones.length > 0) {
      const findedZone = allInfoZones.find((z) => z.id === numberID);
      if (findedZone) {
        setZone(findedZone);
      } else {
        notification.error({
          message: '!Eso no esta!',
          description: `La zona con ID ${id} no esta registrada en la base de datos`,
        });
        setTimeout(() => {
          history.push(SITELINKS.zones.list);
        }, 1500);
      }
    }
  }, [zones, id, history]);
  return zone;
};

const useGetEditablePolygon = (zone, polygonsFromZones) => {
  const [editablePolygon, setEditablePolygon] = useState(null);
  useEffect(() => {
    if (zone) {
      const polygonZone = polygonsFromZones.find((poly) => poly.id === zone.id);
      if (polygonZone) {
        setEditablePolygon(polygonZone);
      } else {
        notification.warn({
          message: 'Advertencia',
          description: 'La zona no tiene dibujada su zona de alcance',
        });
      }
    }
  }, [zone, polygonsFromZones]);
  return editablePolygon;
};

const useGetDefaultDrawingMode = (editablePolygon) => {
  const [defaultMode, setDefaultMode] = useState(DEFAULT_DRAWING_MODE.POLYGON);
  useEffect(() => {
    if (editablePolygon) {
      setDefaultMode(DEFAULT_DRAWING_MODE.HAND);
    }
  }, [editablePolygon]);
  return defaultMode;
};

const useGetShowEditPolygonTool = (editablePolygon) => {
  const [showEditPolygonTool, setShowEditPolygonTool] = useState(true);
  useEffect(() => {
    if (editablePolygon) {
      setShowEditPolygonTool(false);
    }
  }, [editablePolygon]);
  return showEditPolygonTool;
};

const Edit = ({ resetDrawingComponent: ResetDrawing, polygonsFromZones }) => {
  const zones = useSelector(zoneWithGeoJsonSelector);
  const zone = useGetZone(zones);
  const editablePolygon = useGetEditablePolygon(zone, polygonsFromZones);
  const defaultDrawingMode = useGetDefaultDrawingMode(editablePolygon);
  const showEditPolygon = useGetShowEditPolygonTool(editablePolygon);
  const [polygon, setPolygon] = useState(null);
  return (
    <GoogleMap
      showDrawing
      resetDrawingComponent={ResetDrawing}
      defaultDrawingMode={defaultDrawingMode}
      editablePolygon={editablePolygon}
      showEditPolygon={showEditPolygon}
      onPolygonComplete={setPolygon}
      polygons={polygonsFromZones}
      otherComponents={<ZoneEditSubmit zone={zone} polygon={polygon} />}
    />
  );
};

Edit.propTypes = {
  resetDrawingComponent: PropTypes.elementType,
  polygonsFromZones: PropTypes.arrayOf(PropTypes.shape({})),
};

Edit.defaultProps = {
  resetDrawingComponent: null,
  polygonsFromZones: null,
};

export default Edit;
