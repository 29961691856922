import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import useStyles from 'components/Forms/styles';
import { Col, Row, Spin, Button, theme } from 'antd';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import {
  InputField,
  SelectField,
  MultipleSelectField,
  CheckboxField,
} from 'components/Forms';
import { useSelector } from 'react-redux';
import { zoneItemsSelector, zoneLoadedSelector } from 'redux/zones/selectors';
import Card from 'components/card';

const ZoneSelectorMultiselect = () => {
  const zones = useSelector(zoneItemsSelector);
  const loaded = useSelector(zoneLoadedSelector);
  return (
    <MultipleSelectField
      loading={!loaded}
      label="Zonas"
      fieldName="zones"
      fieldId="id"
      fieldDescription="fullName"
      options={zones}
      showSearch
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    />
  );
};

const PaqueryPoint = ({
  initialValues,
  validationSchema,
  onSubmit,
  loaded,
  daysOfWeek,
  currentServices,
  countries,
  setCurrentCountry,
  currentCity,
  cities,
}) => {
  const { token } = theme.useToken();
  const classes = useStyles({ theme: token });
  const formik = useRef();

  useEffect(() => {
    formik.current.setFieldValue('cityID', currentCity);
  }, [currentCity]);

  const optionGenerator = (arrayData) =>
    arrayData.map((data) => ({ label: data.name, value: data.id }));
  const optionsCountries = optionGenerator(countries);
  const optionsCities = optionGenerator(cities);

  return (
    <Spin spinning={!loaded}>
      <Row>
        <Col span={24} className={classes.content}>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            innerRef={formik}
            enableReinitialize
          >
            {({ values }) => (
              <Form>
                <Row type="flex" justify="space-between">
                  <Col xs={24}>
                    <Card
                      title="Información básica"
                      style={{ margin: '8px', height: '98.25%' }}
                    >
                      <Row gutter={[16, 16]} className={classes.cardContent}>
                        <Col xs={24} md={8}>
                          <InputField label="Nombre" fieldName="name" />
                        </Col>
                        <Col xs={24} md={8}>
                          <InputField label="Dirección" fieldName="address" />
                        </Col>
                        <Col xs={24} md={8}>
                          <InputField
                            label="Teléfono"
                            fieldName="phone"
                            type="number"
                          />
                        </Col>
                        <Col xs={24} md={19} lg={20}>
                          <Row gutter={[16, 16]}>
                            <Col xs={24} md={16}>
                              <InputField label="Detalles" fieldName="detail" />
                            </Col>
                            <Col xs={24} md={8}>
                              <InputField
                                label="Contacto"
                                fieldName="contactName"
                              />
                            </Col>
                            <Col xs={24} md={12}>
                              <InputField
                                label="Volumen de almacenamiento"
                                fieldName="volume"
                              />
                            </Col>
                            <Col xs={24} md={12}>
                              <MultipleSelectField
                                label="Servicios"
                                fieldName="currentServices"
                                fieldId="value"
                                fieldDescription="description"
                                options={currentServices}
                                values={values}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={24} md={5} lg={4}>
                          <Row
                            gutter={[
                              { xss: 0, md: 16 },
                              { xs: 0, md: 24 },
                            ]}
                          >
                            <Col span={24}>
                              <CheckboxField
                                label="Activo"
                                fieldName="active"
                              />
                            </Col>
                            <Col span={24}>
                              <CheckboxField
                                label="Publicado"
                                fieldName="published"
                              />
                            </Col>
                            <Col span={24}>
                              <CheckboxField
                                label="Privado"
                                fieldName="private"
                              />
                            </Col>
                            <Col span={24}>
                              <CheckboxField
                                label="Abierto"
                                fieldName="openBranchOffice"
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col xs={24} md={8}>
                    <Card
                      title="Horarios de atención"
                      style={{ margin: '8px', height: '98.25%' }}
                    >
                      <Row gutter={[16, 16]} className={classes.cardContent}>
                        <Col span={24}>
                          <MultipleSelectField
                            label="Dias de atención"
                            fieldName="daysOfWeek"
                            fieldId="value"
                            fieldDescription="description"
                            options={daysOfWeek}
                            values={values}
                          />
                        </Col>
                        <Col span={12}>
                          <InputField
                            label="Apertura"
                            fieldName="initHour"
                            type="number"
                            min={0}
                            max={23}
                          />
                        </Col>
                        <Col span={12}>
                          <InputField
                            label="Cierre"
                            fieldName="finishHour"
                            type="number"
                            min={0}
                            max={23}
                          />
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col xs={24} md={8}>
                    <Card
                      title="Zonas de trabajo"
                      style={{ margin: '8px', height: '98.25%' }}
                    >
                      <Row className={classes.cardContent}>
                        <Col span={24}>
                          <ZoneSelectorMultiselect />
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col xs={24} md={8}>
                    <Card
                      title="Información de contacto"
                      style={{ margin: '8px', height: '98.25%' }}
                    >
                      <Row className={classes.cardContent} gutter={[16, 16]}>
                        <Col span={24}>
                          <InputField
                            label="Codigo Postal"
                            fieldName="postalCode"
                          />
                        </Col>
                        <Col span={24}>
                          <SelectField
                            label="País"
                            fieldName="countryID"
                            onChange={(value) => setCurrentCountry(value)}
                            options={optionsCountries}
                          />
                        </Col>
                        <Col span={24}>
                          <SelectField
                            label="Ciudad"
                            fieldName="cityID"
                            options={optionsCities}
                          />
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col
                    span={24}
                    style={{ display: 'flex', justifyContent: 'end' }}
                  >
                    <Button type="primary" htmlType="submit" size="large">
                      Guardar
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </Spin>
  );
};

PaqueryPoint.propTypes = {
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  loaded: PropTypes.bool,
  daysOfWeek: PropTypes.arrayOf(PropTypes.object),
  currentServices: PropTypes.arrayOf(PropTypes.object),
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  setCurrentCountry: PropTypes.func.isRequired,
  currentCity: PropTypes.number,
  cities: PropTypes.arrayOf(PropTypes.object),
  validationSchema: PropTypes.shape({}),
};

PaqueryPoint.defaultProps = {
  initialValues: {
    name: '',
    address: '',
    phone: '',
    detail: '',
    contactName: '',
    volume: 0,
    currentServices: [],
    daysOfWeek: [],
    zones: [],
    countryID: 1,
    initHour: 9,
    finishHour: 18,
    postalCode: '',
    published: false,
    private: false,
    active: false,
    openBranchOffice: false,
  },
  currentCity: 1,
  cities: [],
  onSubmit: () => {},
  loaded: false,
  daysOfWeek: null,
  currentServices: null,
  validationSchema: null,
};

export default PaqueryPoint;
