import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'antd';
import { useSelector } from 'react-redux';
import { zonesPolygonsSelector } from 'redux/zones/selectors';
import SITELINKS from 'constants/sitelinks';
import Add from './components/add';
import Edit from './components/edit';
import Show from './components/show';

const ResetDrawing = ({ onResetDrawing }) => {
  const ResetButton = ({ text }) => (
    <Button
      danger
      style={{
        position: 'absolute',
        marginTop: '62px',
        marginLeft: '15px',
        boxShadow: '1px 1px 1px 0px #ccc',
      }}
      onClick={() => {
        onResetDrawing();
      }}
    >
      {text}
    </Button>
  );

  ResetButton.propTypes = {
    text: PropTypes.string.isRequired,
  };

  return (
    <Switch>
      <Route path={SITELINKS.zones.edit.route}>
        <ResetButton text="Volver al original" />
      </Route>
      <Route path={SITELINKS.zones.add}>
        <ResetButton text="Volver a dibujar" />
      </Route>
    </Switch>
  );
};

ResetDrawing.propTypes = {
  onResetDrawing: PropTypes.func.isRequired,
};
const Zone = () => {
  const zones = useSelector(zonesPolygonsSelector);
  return (
    <>
      <Row justify="center" style={{ height: '100%' }}>
        <Col span={24}>
          <Switch>
            <Route
              path={SITELINKS.zones.add}
              render={(props) => (
                <Add
                  {...props}
                  resetDrawingComponent={ResetDrawing}
                  zones={zones}
                />
              )}
            />
            <Route
              path={SITELINKS.zones.edit.route}
              render={(props) => (
                <Edit
                  {...props}
                  resetDrawingComponent={ResetDrawing}
                  polygonsFromZones={zones}
                />
              )}
            />
            <Route
              path={SITELINKS.zones.viewAll}
              render={(props) => (
                <Show
                  {...props}
                  resetDrawingComponent={ResetDrawing}
                  polygonsFromZones={zones}
                />
              )}
            />
          </Switch>
        </Col>
      </Row>
    </>
  );
};

export default Zone;
