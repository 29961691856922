import React, { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import JsDownload from 'js-file-download';
import PaqueryTable, { TableHeader } from '@paquery-team/lib-table';
import { getCaronteBasicAuth } from 'constants/operations';
import API from 'constants/api';
import { TableDateTimeFormat } from 'constants/dateFormats';
import rest from 'util/Api';
import { actions } from 'redux/massives/slice';
import {
  loadedSelector,
  itemsSelector,
  pageableSelector,
} from 'redux/massives/selectors';
import { authProfile } from 'redux/auth/selectors';
import useMassives from 'redux/massives';
import { notification, Button } from 'antd';
import useMarketplaces from 'redux/marketplaces';
import DownloadLabelButton from './components/downloadLabelButton';
import PackagesList from './components/packagesList';

const codeColumn = {
  align: 'center',
  title: 'Codigo',
  dataIndex: 'code',
  width: 220,
};

const creationDateColumn = {
  title: 'Fecha de creación',
  align: 'center',
  dataIndex: 'creationDate',
};

const successPackagesColumn = {
  title: 'Paquetes Creados',
  align: 'center',
  dataIndex: 'successCountDetail',
};

const failedPackagesColumn = {
  title: 'Paquetes con errores',
  align: 'center',
  dataIndex: 'errorCountDetail',
};

const downloadLabelColumn = {
  title: 'Etiquetas',
  align: 'center',
  render: (_, record) => <DownloadLabelButton record={record} />,

  width: 120,
};

const packagesColumn = {
  title: 'Paquetes',
  align: 'center',
  render: (_, departure) => <PackagesList code={departure.code} />,
  width: 120,
};

const dataColumns = [
  codeColumn,
  creationDateColumn,
  successPackagesColumn,
  failedPackagesColumn,
  downloadLabelColumn,
  packagesColumn,
];

const columnsSmallDevice = [
  codeColumn,
  creationDateColumn,
  downloadLabelColumn,
  packagesColumn,
];

const columnsMediumDevice = [
  codeColumn,
  creationDateColumn,
  successPackagesColumn,
  downloadLabelColumn,
  packagesColumn,
];

const columnsLargeDevice = [...dataColumns];

const PackageMassiveList = () => {
  useMassives();
  useMarketplaces({ initialize: true });
  const inputFile = useRef();
  const dispatch = useDispatch();
  const loaded = useSelector(loadedSelector);
  const profile = useSelector(authProfile);
  const items = useSelector(itemsSelector);
  const pageable = useSelector(pageableSelector);

  const searchCallback = useCallback(
    (value) => {
      dispatch(actions.updateSearch(value));
    },
    [dispatch],
  );
  const updateDate = useCallback(
    (value) => {
      dispatch(actions.updateDate(value));
    },
    [dispatch],
  );
  const updatePaginate = useCallback(
    (page) => {
      dispatch(actions.updatePaginate(page));
    },
    [dispatch],
  );
  const searcher = {
    onSearching: searchCallback,
    placeholder: 'Código',
    allowEmptySearch: true,
  };
  const rangePicker = {
    onDateSelection: updateDate,
    required: {
      value: true,
      message: 'La fecha es requerida',
    },
  };

  const handleUpload = async ({ target: { files } }) => {
    try {
      const URL = API.massive.packagesUpload(profile.id);
      const body = new FormData();
      body.append('0', files[0]);
      const options = {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: getCaronteBasicAuth(),
        },
        responseType: 'blob',
      };
      const response = await rest.post(URL, body, options);

      if (!rest.isSuccessResponse(response)) throw new Error(response.message);
      if (
        response.headers['content-type'] ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        JsDownload(response.data, 'pq_import_result.xlsx');
      } else {
        notification.success({
          message: 'Alta masiva realizada correctamente',
          description: 'Los paquetes han sido cargados correctamente',
        });
        // TODO: agregar alta masiva generada al listado de altas masivas
      }
      dispatch(actions.refreshPage());
    } catch (error) {
      notification.error({
        message: 'Ocurrio un problema',
        description:
          'Verifique los campos requeridos para las altas de paquetes esten cargados.',
      });
    }
  };

  let filteredItems;
  if (loaded && items) {
    filteredItems = items.map((item) => ({
      ...item,
      key: item.code,
      creationDate: item.creationDate
        ? dayjs(item.creationDate).tz().format(TableDateTimeFormat)
        : null,
      successCountDetail: item.successCount ? item.successCount : 'No',
      errorCountDetail: item.errorCount ? item.errorCount : '-',
    }));
  }
  return (
    <>
      <input
        type="file"
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={handleUpload}
      />
      <PaqueryTable
        loading={!loaded}
        header={{
          title: 'Alta masiva',
          searcher,
          rangePicker,
          primaryButton: (
            <TableHeader.PrimaryButton
              title="Subir"
              onClick={() => inputFile.current.click()}
            />
          ),
          secondaryButton: (
            <a href={API.massive.packagesDownload}>
              <Button>Descargar Template</Button>
            </a>
          ),
          refresh: () => dispatch(actions.refreshPage()),
        }}
        onChangePaginate={updatePaginate}
        dataSource={filteredItems}
        paginate={pageable}
        dataColumns={dataColumns}
        colsForSmallDevice={columnsSmallDevice}
        colsForMediumDevice={columnsMediumDevice}
        colsForLargeDevice={columnsLargeDevice}
      />
    </>
  );
};

export default PackageMassiveList;
