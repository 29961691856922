import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { DriverDetail } from '@paquery-team/lib-driver-detail';
import rest, { getData } from 'util/Api';
import { useSelector, useDispatch } from 'react-redux';
import {
  countriesGlobalsSelector,
  vehicleTypesSelector,
} from 'redux/globals/selectors';
import {
  paquerviewAvatarSelector,
  paquerviewLoadingSelector,
} from 'redux/paquerView/selectors';
import { actions } from 'redux/paquerView/slice';
import { actions as ppointDepartureActions } from 'redux/packagePaquerypointDeparture/slice';
import usePaquerview from 'redux/paquerView';
import { notification } from 'antd';
import { ContainerOutlined, HistoryOutlined } from '@ant-design/icons';
import { IMAGE_REGEX } from 'constants/defaultValues';
import API from 'constants/api';
import * as Yup from 'yup';
import { zoneItemsSelector } from 'redux/zones/selectors';
import usePackagePpointDeparture from 'redux/packagePaquerypointDeparture';
import { packagePaquerypointDepartureAllPaqueryPointsItemsSelector } from 'redux/packagePaquerypointDeparture/selectors';
import PaquerPackages from './components/packages';
import PaquerHistory from './components/history';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Es necesario indicar el nombre del paquer'),
  lastname: Yup.string().required(
    'Es necesario indicar el apellido del paquer',
  ),
  docNumber: Yup.number()
    .typeError('Solo se deben indicar números y es requerido')
    .required('El número de documento es necesario'),
  mobile: Yup.number()
    .typeError('Solo deben ser numeros y son requeridos')
    .required('Es necesario indicar el número de teléfono'),
  email: Yup.string()
    .email('El formato de correo no es correcto')
    .required('Es necesario indicar el correo electrónico'),
  city: Yup.object().shape({
    id: Yup.number()
      .typeError('Es necesario indicar la ciudad')
      .required('Es necesario indicar la ciudad'),
    countryID: Yup.number()
      .typeError('Es necesario indicar el país')
      .required('Es necesario indicar el país'),
  }),
});

const initialValues = {
  name: '',
  lastname: '',
  docNumber: null,
  cbu: null,
  cuil: null,
  pwd: '',
  birthDate: null,
  prefix: '',
  phone: null,
  active: true,
  confirmpassword: '',
  cityID: null,
  countryID: null,
  vehicleTypeID: null,
  zones: [],
  paqueryPoints: [],
};

const disabledInputs = {
  email: true,
};

/**
 * Returns if the paquer is active by its paquer.status property
 * @param {number} status
 * */
const isPaquerActive = (status) => {
  if (status === 1) return true;
  if (status === 6) return false;
  return false;
};

const PaquerModify = () => {
  usePaquerview();
  usePackagePpointDeparture();
  const dispatch = useDispatch();
  const loading = useSelector(paquerviewLoadingSelector);
  const avatar = useSelector(paquerviewAvatarSelector);
  const zones = useSelector(zoneItemsSelector);
  const vehicleTypes = useSelector(vehicleTypesSelector);
  const paqueryPoints = useSelector(
    packagePaquerypointDepartureAllPaqueryPointsItemsSelector,
  );
  const countries = useSelector(countriesGlobalsSelector);
  const [paquerData, setPaquerData] = useState(null);
  const [currentCity, setCurrentCity] = useState(null);
  const [cities, setCities] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const { paquerId } = useParams();

  useEffect(() => {
    dispatch(actions.reset());
    dispatch(ppointDepartureActions.initial());
    dispatch(actions.setDriverId(paquerId));
  }, [dispatch, paquerId]);

  const onChangeCountry = useCallback(
    async (country) => {
      dispatch(actions.loading());
      const citiesResponse = await getData(`${API.globals.cities}/${country}`);
      setCities(citiesResponse);
      if (citiesResponse.length > 0) {
        setCurrentCity(citiesResponse[0].id);
      } else {
        setCurrentCity(null);
      }
      dispatch(actions.loaded());
    },
    [dispatch],
  );

  const onChangePassword = async (email) => {
    try {
      const body = {
        email,
      };
      const bodyParsed = new URLSearchParams(body);
      const options = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      await rest.post(
        `${API.user.changePassword}`,
        bodyParsed.toString(),
        options,
      );
    } catch (error) {
      notification.error({
        message: 'Ocurrio un problema',
        description: `Hubo un error al solicitar el cambio de contraseña${
          error.message ? `: ${error.message}` : '.'
        }`,
      });
    }
  };

  useEffect(() => {
    const getPaquer = async () => {
      try {
        dispatch(actions.loading());
        const paquer = await getData(`${API.paquer.get}${paquerId}`);
        // Esto es auxiliar, API.zones.getById es un endpoint auxiliar que devulve las zonas por id.
        // esto es en orden de transicionar de la feature vieja de zonas (zonesByOpl) a la nueva (load-zones) sin dejar paquetes sin zonas
        // Una vez que no hayan paquetes con zonas viejas, quitar esto y proveer de la feature de zonas nuevas
        // const paquerZonesStr = paquer.zones.map((zoneObj) => zoneObj.id).join();
        // let allZones = await getData(
        //   `${API.zones.getById}?zoneIds=${paquerZonesStr}`,
        // );
        // allZones = allZones.map((zone) => {
        //   return {
        //     ...zone,
        //     fullName: `${zone.name} - ${zone.detail}`,
        //   };
        // });

        const paqueryPointZonesStr = paquer.paqueryPoints
          .map((zoneObj) => zoneObj.id)
          .join();
        let dataPaqueryPoint = await getData(
          `${API.paqueryPoint.getByIds}?ppIDs=${paqueryPointZonesStr}`,
        );
        dataPaqueryPoint = dataPaqueryPoint.map((zone) => {
          return {
            ...zone,
            fullName: `${zone.name} - ${zone.detail}`,
          };
        });

        setPaquerData({
          ...paquer,
          lastname: paquer.lastName,
          isActive: isPaquerActive(paquer.status),
          cityID: paquer.city.id,
          countryID: paquer.city.countryID,
          zones: paquer.zones
            .filter((zone) => zone != null)
            .map(
              (zone) =>
                zones.find((zoneInRedux) => zoneInRedux.id === zone.id) || {
                  id: zone.id,
                  name: 'ZONA DESCONOCIDA',
                },
            ),
          paqueryPoints: paquer.paqueryPoints
            .filter((zone) => zone != null)
            .map(
              (zone) =>
                dataPaqueryPoint.find(
                  (zoneInRedux) => zoneInRedux.id === zone.id,
                ) || {
                  id: zone.id,
                  name: 'ZONA DESCONOCIDA',
                },
            ),
        });

        dispatch(actions.setAvatar({ data: paquer.avatar }));

        if (paquer.city.countryID) {
          const citiesResponse = await getData(
            `${API.globals.cities}/${paquer.city.countryID}`,
          );
          setCities(citiesResponse);
        }
      } catch (error) {
        notification.error({
          message: 'Ha ocurrido un error',
          description: 'No se pudo obtener el paquer indicado.',
        });
        setTimeout(() => {
          history.goBack();
        }, 1500);
      } finally {
        dispatch(actions.loaded());
      }
    };
    if (!paquerData) {
      getPaquer();
    }
  }, [paquerId, location, history, dispatch, countries, paquerData, zones]);

  const handleSubmit = async (values) => {
    const payload = {
      // status === 1 -> active, status === 6 -> inactive
      status: values.isActive === true ? 1 : 6,
      avatar: avatar.name ? avatar.data.replace(IMAGE_REGEX, '') : undefined,
      birthDate: values.birthDate,
      cityID: values.city.id,
      countryId: values.city.countryID,
      vehicleTypeID: values.vehicleTypeID,
      cbu: values.cbu,
      cuil: values.cuil,
      docNumber: values.docNumber,
      email: values.email,
      lastName: values.lastname,
      mobile: values.mobile,
      name: values.name,
      zones: values.zones.map((zone) => ({
        id: zone.id,
      })),
      paqueryPoints: values.paqueryPoints.map((ppoint) => ({
        id: ppoint.id,
      })),
      logisticOperators: values.logisticOperators,
      vehicleID: values.vehicleID,
      vehicleType: values.vehicleType,
    };
    const response = await rest.put(
      `${API.paquer.update}${values.id}`,
      payload,
    );
    if (response.status === 400) {
      throw new Error(response.data.message);
    }
  };

  return (
    <DriverDetail
      loaded={!loading}
      initialValues={paquerData || initialValues}
      validationSchema={validationSchema}
      disabledInputs={disabledInputs}
      currentCity={currentCity}
      vehicles={vehicleTypes}
      countries={countries}
      cities={cities}
      zones={zones}
      paqueryPoints={paqueryPoints}
      onChangePassword={onChangePassword}
      onChangeCountry={onChangeCountry}
      onSubmit={handleSubmit}
      lists={[
        {
          title: 'En tránsito',
          icon: <ContainerOutlined />,
          table: PaquerPackages,
        },
        {
          title: 'Histórico',
          icon: <HistoryOutlined />,
          table: PaquerHistory,
        },
      ]}
      isModify
    />
  );
};
export default PaquerModify;
